$(function () {

	var hight = '70px';
	if (screen.width > 3839) {
		hight = '190px';
	} else if (screen.width > 2879 && screen.width < 3839) {
		hight = '170px';
	} else if (screen.width > 1900 && screen.width < 2879) {
		hight = '90px';
	} else {
		hight = '100px';
	}

	$('#onleaveticker').totemticker({
		row_height: hight,
		mousestop: true,
		start: '#onleaveticker_start',
		direction: 'up',
	});
	// $('#outofoffice').totemticker({
	// 	row_height: hight,
	// 	mousestop: true,
	// 	// start:'#onleaveticker'
	// });

	setTimeout(() => {
		$('#onleaveticker_start').trigger('click');
	}, 5000);

});

$('#feedback').carousel({
	interval: 15000
})

$('#widgetsider').carousel({
	interval: 25000
})

$(document).ready(function () {
	setTimeout(() => {
		$('#onleaveticker_start').trigger('click');
	}, 5000);
	$('.top-navigation .btn, .top-navigation li a').click(function () {
		$(".mobile-menu").slideToggle("slow");
		$('.top-navigation').toggleClass('open');
	});

	$(document).on('click', '.mobile-menu li a[href^="#"]', function (e) {
		e.preventDefault();
		$('html, body').stop().animate({
			scrollTop: $($(this).attr('href')).offset().top - 30
		}, 1000, 'linear');
	});

});

